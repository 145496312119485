import React, { useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"

const OurSpecialists = ({ allWpSpecialist }) => {
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (modal !== false) {
      document.querySelector("body").classList.add("specialists-modal--open")
    } else {
      document.querySelector("body").classList.remove("specialists-modal--open")
    }
  }, [modal])

  return (
    <Layout>
      <SEO title="Nasi Specjaliści" />
      <GlobalTop title="Nasi Specjaliści" />

      <section className="specialists-content">
        <div className="container">
          <div className="row">
            {allWpSpecialist.nodes.map((node, index) => (
              <div className="col-xl-3 col-md-4 col-sm-6" key={index}>
                <div className="specialists-content__tile">
                  <div
                    className="specialists-content__tile-thumb"
                    style={{
                      backgroundImage: `url(${
                        node.featuredImage
                          ? node.featuredImage.node.sourceUrl
                          : ""
                      })`,
                    }}
                  ></div>
                  <div className="specialists-content__tile-content">
                    <div className="specialists-content__tile-content-wrapper">
                      <h4>{node.title}</h4>
                      <span>{node.acfSpecialists.specjalizacja}</span>
                      <div className="specialists-content__tile-actions">
                        {node.acfSpecialists.specjalistaProassistIframeUrl && (
                          <button
                            onClick={e => setModal(`register-${index}`)}
                            className="btn-custom btn-custom--turqoise btn-custom--small"
                          >
                            Rejestracja
                          </button>
                        )}
                        <button
                          onClick={e => setModal(`bio-${index}`)}
                          className="btn-custom btn-custom--purple btn-custom--small"
                          style={{
                            marginTop: node.acfSpecialists
                              .specjalistaProassistIframeUrl
                              ? "10px"
                              : "0px",
                          }}
                        >
                          Wyświetl bio
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Registration */}
      {allWpSpecialist.nodes.map(
        (node, index) =>
          modal === `register-${index}` && (
            <div
              className="specialists-modal specialists-modal--register"
              key={`reigster-${index}`}
            >
              <div className="specialists-modal__inner">
                <button
                  onClick={e => setModal(false)}
                  className="specialists-modal__inner-close"
                >
                  Zamknij
                </button>
                <div>
                  <h2>{node.title}</h2>
                  <h4>{node.acfSpecialists.specjalizacja}</h4>
                  <iframe
                    src={node.acfSpecialists.specjalistaProassistIframeUrl}
                    width="100%"
                    height="496"
                    frameBorder="0"
                    title={node.title}
                  />
                </div>
              </div>
            </div>
          )
      )}

      {/* View bio */}
      {allWpSpecialist.nodes.map(
        (node, index) =>
          modal === `bio-${index}` && (
            <div className="specialists-modal" key={`bio-${index}`}>
              <div className="specialists-modal__inner">
                <button
                  onClick={e => setModal(false)}
                  className="specialists-modal__inner-close"
                >
                  Zamknij
                </button>
                <div className="row">
                  <div className="col-md-3">
                    {node.featuredImage && (
                      <img
                        className="img-fluid"
                        src={node.featuredImage.node.sourceUrl}
                        alt={node.title}
                      />
                    )}
                  </div>
                  <div
                    className={
                      node.acfSpecialists.specjalistaProassistIframeUrl
                        ? "col-md-5"
                        : "col-md-9"
                    }
                  >
                    <h2>{node.title}</h2>
                    <h4>{node.acfSpecialists.specjalizacja}</h4>
                    <div
                      className="specialists-modal__description"
                      dangerouslySetInnerHTML={{ __html: node.content }}
                    />
                  </div>
                  {node.acfSpecialists.specjalistaProassistIframeUrl && (
                    <div className="col-md-4">
                      <iframe
                        src={node.acfSpecialists.specjalistaProassistIframeUrl}
                        width="100%"
                        height="496"
                        frameBorder="0"
                        title={node.title}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
      )}
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allWpSpecialist {
          nodes {
            id
            title
            content
            acfSpecialists {
              specjalizacja
              specjalistaProassistIframeUrl
            }
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    `}
    render={data => <OurSpecialists {...data} />}
  />
)
